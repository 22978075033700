import React, { useRef, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import * as style from './Category.module.scss';
import MaxWidth from '../MaxWidth/MaxWidth';
import Arrow from '../Arrow/Arrow';

export default function Category({ data }) {
  // Thumbnail component
  const GalleryThumbnail = ({ gallery }) => {
    const { gallery_name: name, gallery_image: image, category: categoryName, slug } = gallery;
    return (
      <li className={style.thumb}>
        <Link to={`/folio/${categoryName.slug.current}/${slug.current}`} className={style.link}>
          <div className={style.square}>
            <Img fluid={image.asset.fluid} className={style.thumbImage} />
            <div className={style.hoverDark} />
          </div>
          <p className={style.name}>{name}</p>
        </Link>
      </li>
    );
  };

  const BackLink = () => {
    const linkRef = useRef(null);

    useEffect(() => {
      linkRef.current.style.opacity = 1;
    });

    return (
      <Link ref={linkRef} to="/folio" className={style.back} style={{ opacity: 0, transition: 'opacity 1s' }}>
        <Arrow />
        categories
      </Link>
    );
  };

  const category = data.allSanityCategory.nodes[0].category_name;
  const galleries = data.allSanityGallery.nodes;

  return (
    <>
      <Helmet>
        <title>{category} | Ottone Photography</title>
      </Helmet>
      <MaxWidth>
        <div className={style.titleAndBackButton}>
          <BackLink />
          <h1 className={style.title}>{category}</h1>
        </div>
        <ul className={style.thumbGrid}>
          {galleries.map((gallery, i) => {
            return <GalleryThumbnail gallery={gallery} key={`galThumb${i}`} />;
          })}
        </ul>
      </MaxWidth>
    </>
  );
}

export const query = graphql`
  query($id: String!) {
    allSanityCategory(filter: { id: { eq: $id } }) {
      nodes {
        category_name
      }
    }
    allSanityGallery(filter: { category: { id: { eq: $id } } }) {
      nodes {
        gallery_name
        slug {
          current
        }
        category {
          slug {
            current
          }
        }
        gallery_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
